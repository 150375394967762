<template>
  <b-card>

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="12">
          <b-form-group
            label="E-mail"
            label-for="e-mail"
          >
            <b-form-input
              v-model="configEmail.email"
              name="email"
              placeholder="Email"
              type="email"
            />

          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group
            label-for="account-new-password"
            label="Password"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-new-password"
                v-model="configEmail.password"
                :type="passwordFieldTypeNew"
                name="new-password"
                placeholder="Password"
                maxlength="70"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconNew"
                  class="cursor-pointer"
                  @click="togglePasswordNew"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6">
          <b-form-group
            label="Servidor"
            label-for="servidor"
          >
            <b-form-input
              v-model="configEmail.servidor"
              name="servidor"
              placeholder="Servidor"
            />
          </b-form-group>
        </b-col>

        <b-col sm="3">
          <b-form-group
            label="Porta"
            label-for="porta"
          >
            <b-form-input
              v-model="configEmail.porta"
              name="porta"
              placeholder="Porta"
              type="number"
            />
          </b-form-group>
        </b-col>
        <b-col sm="3">
          <b-form-group
            label="SSL"
            label-for="ssl"
          >
            <b-form-checkbox
              id="renovavel"
              v-model="configEmail.ssl"
              checked="ssl"
              name="ssl"
              switch
              inline
              :value="true"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6">
          <b-form-group
            label="Nome"
            label-for="nome"
          >
            <b-form-input
              v-model="configEmail.nome"
              name="nome"
              placeholder="Nome"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <b-form-group
            label="Assunto"
            label-for="assunto"
          >
            <b-form-input
              v-model="configEmail.assunto"
              placeholder="Assunto"
              name="assunto"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="guardar"
          >
            Guardar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol,
  BCard,
  BInputGroup, BInputGroupAppend, BFormCheckbox,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,

  },
  directives: {
    Ripple,
  },
  props: {
    configEmail: Object,
  },
  data() {
    return {
      passwordFieldTypeNew: 'password',
    }
  },
  computed: {

    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },

  },
  methods: {
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },

    guardar() {
      if (this.configEmail.id === null) {
        axios.post('/api/v1/configMail', this.configEmail)
          .then(res => {
            this.configEmail.id = res.data.id
            this.sucesso('sucess', 'CheckIcon')
            this.$forceUpdate()
          })
      } else {
        axios.put(`/api/v1/configMail/${this.configEmail.id}`, this.configEmail)
          .then(res => {
            this.sucesso('sucess', 'CheckIcon')
            this.$forceUpdate()
          })
      }
    },
    sucesso(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'sucesso',
          icon,
          text: 'Sucesso !',
          variant,
        },

      })
    },

  },

}
</script>
