<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Email</span>
      </template>

      <ConfiguracaoEmail
        :config-email="configEmail"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import axios from 'axios'
import ConfiguracaoEmail from './ConfiguracaoEmail.vue'

export default {
  components: {
    BTabs,
    BTab,
    ConfiguracaoEmail,
  },
  data() {
    return {
      configEmail: {
        id: null,
        nome: null,
        email: null,
        password: null,
        servidor: null,
        porta: null,
        ssl: null,
        assunto: null,
      },
    }
  },
  created() {
    axios.get('/api/v1/configMail')
      .then(res => {
        if (res.data !== '') {
          
          this.configEmail = res.data
          this.configEmail.password = ''
          if(res.data.ssl = 1 ){
            this.configEmail.ssl = true
          }
        }
      })
  },
}
</script>
